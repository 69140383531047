<template>
  <Head>
    <title>M-Changa Affiliates | M-Changa Africa</title>
    <meta
      name="description"
      content="M-Changa Africa: Start your fundraiser in three quick steps."
    />
    <meta name="author" content="Mobi Changa" />
    <meta property="og:type" content="website" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta property="og:url" :content="this.baseURL + '/signup'" />
    <meta property="og:title" content="Sign Up | M-Changa Africa" />
    <meta
      property="og:description"
      content="M-Changa Africa: Start your fundraiser in three quick steps."
    />
    <meta property="fb:app_id" :content="this.facebookID" />
  </Head>
  <div>
    <section id="content">
      <div class="content-wrap py-0">
        <div class="section nobg nomargin">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="heading-block center">
                  <h2>Register for the M-Changa Affiliate Program</h2>
                </div>
                <div>
                  <h3 class="mb-1">About The Program</h3>
                  <p class="mb-1">
                    M-Changa, a mobile money-based fundraising platform, has
                    become Africa's largest crowdfunding platform. To date, over
                    35,000 Kenyan fundraisers have raised millions of dollars
                    from over 500,000 contributors. Primary users include those
                    raising for medical bills, disasters, weddings,
                    organisations and churches.
                  </p>
                  <ul class="pl-2">
                    <li><strong>Job Title:</strong> Sales Affiliate</li>
                    <li><strong>Job Type:</strong> Commission based</li>
                    <li>
                      <strong>Location:</strong> Kenya, Uganda, Nigeria, Ghana,
                      Tanzania
                    </li>
                    <li><strong>Job Field:</strong> Sales/Marketing</li>
                  </ul>
                </div>
                <div>
                  <h3 class="mb-1">Registration</h3>
                  <ol class="pl-4" style="list-style: number">
                    <li>
                      Fill out the form above and select submit. Candidates must
                      answer all questions on the registration correctly.
                    </li>
                    <li>
                      Upon successful registration, candidates will receive an
                      SMS to confirm they are now an affiliate.
                    </li>
                    <li>
                      Candidates will receive a second SMS containing a unique
                      referral link.
                    </li>
                    <li>
                      Affiliates should share their unique referral link to
                      potential fundraisers.
                    </li>
                  </ol>
                </div>

                <p>
                  Affiliates who perform well may qualify for additional
                  benefits of working with the M-Changa team.
                </p>
                <p>
                  For more information, visit www.mchanga.africa or contact our
                  customer care team on +254 20 765 0919 or email
                  support@mchanga.africa
                </p>

                <h4>Affiliate Details</h4>
                <form
                  class="form-register-wrapper"
                  name="affiliateForm"
                  id="affiliateForm"
                  @submit.prevent="onSubmit"
                >
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.fullname.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Full Name*"
                      name="fullname"
                      v-model="fullname"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.phone.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <input
                      v-model="phone"
                      type="text"
                      class="form-control"
                      placeholder="Phone Number*"
                      name="phone"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.country.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <select
                      class="form-control"
                      name="country"
                      v-model="country"
                    >
                      <option value="">Country</option>
                      <option value="ke">Kenya</option>
                      <option value="ug">Uganda</option>
                      <option value="ng">Nigeria</option>
                      <option value="gh">Ghana</option>
                      <option value="tz">Tanzania</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.occupation.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <select
                      class="form-control"
                      name="occupation"
                      v-model="occupation"
                    >
                      <option value="">Occupation</option>
                      <option value="employee">Employed</option>
                      <option value="business">Self Employed</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.referral_code.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Preferred Affiliate Referral Code*"
                      name="referral_code"
                      v-model="referral_code"
                    />
                  </div>
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.organization.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <select
                      class="form-control"
                      name="organization"
                      v-model="organization"
                    >
                      <option value="">What is M-Changa?</option>
                      <option value="1">A betting platform</option>
                      <option value="2">A loan platform</option>
                      <option value="3">A fundraising platform</option>
                      <option value="4">An insurance platform</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.payments.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <select
                      class="form-control"
                      name="payments"
                      v-model="payments"
                    >
                      <option value="">
                        What form of payments are accepted?
                      </option>
                      <option value="1">Visa & MasterCard only</option>
                      <option value="2">Visa, MasterCard & PayPal only</option>
                      <option value="3">M-Pesa only</option>
                      <option value="4">
                        Visa, MasterCard, PayPal, M-Pesa, Flutterwave, Airtel
                        Money, Equitel & T-Kash
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.campaigns.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <select
                      class="form-control"
                      name="campaigns"
                      v-model="campaigns"
                    >
                      <option value="">
                        How many fundraisers have used M-Changa?
                      </option>
                      <option value="1">0 - 999</option>
                      <option value="2">1,000 - 9,999</option>
                      <option value="3">10,000 - 30,000</option>
                      <option value="4">30,000+</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div
                      class="error-msg"
                      v-for="error of v$.causes.$errors"
                      :key="error.$uid"
                    >
                      {{ error.$message }}
                    </div>
                    <select class="form-control" name="causes" v-model="causes">
                      <option value="">
                        What type of causes are allowed onto M-Changa?
                      </option>
                      <option value="1">Medial & funeral</option>
                      <option value="2">Education</option>
                      <option value="3">Organisations & religion</option>
                      <option value="4">
                        All of the above (excluding politics)
                      </option>
                    </select>
                  </div>

                  <div>
                    <div
                      id="till_error"
                      class="error-msg m-1"
                      v-if="affiliateError"
                    >
                      {{ affiliateError }}
                    </div>
                    <div
                      id="till_success"
                      class="success m-1"
                      v-if="affiliateSuccess"
                    >
                      {{ affiliateSuccess }}
                    </div>
                  </div>

                  <div class="form-submit">
                    <p>
                      By clicking "Register", you agree to M-Changa Africa's
                      <a href="/mchanga-affiliate-terms">Terms & Conditions</a>
                    </p>
                    <div class="flex justify-left">
                      <button
                        class="button mt-1 w-44"
                        @click="addAffiliate"
                        :disabled="this.affErr"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
  
<script>
import useVuelidate from "@vuelidate/core";
import { Head } from "@vueuse/head";
import Footer from "../components/Footer/index.vue";
import { required, minLength, numeric, alphaNum } from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      fullname: "",
      phone: "",
      country: "",
      occupation: "",
      referral_code: "",
      organization: "",
      payments: "",
      campaigns: "",
      causes: "",
      affiliateSuccess: "",
      affiliateError: "",
      m_loader: 0,
      affErr: 0,
    };
  },
  validations() {
    return {
      fullname: { required, min: minLength(5) },
      country: { required },
      phone: { required, min: minLength(10), numeric },
      occupation: { required },
      referral_code: {
        required,
        min: minLength(4),
        regex: /^(?=.*?[a-zA-Z])[a-zA-Z\d]+$/,
      },
      organization: { required },
      payments: { required },
      campaigns: { required },
      causes: { required },
    };
  },

  methods: {
    async addAffiliate() {
      this.m_loader = 1;
      this.affErr = 1;
      this.affiliateError = "";
      this.affiliateSuccess = "";
      const result = await this.v$.$validate(this.affiliateForm);
      if (!result) {
        this.m_loader = 0;
        this.affErr = 0;
        return;
      }

      if (
        this.organization != "3" ||
        this.payments != "4" ||
        this.campaigns != "4" ||
        this.causes != "4"
      ) {
        this.affErr = 0;
        this.affiliateError = "One or more questions not answered correctly!";
        return;
      }

      if (!/[a-zA-Z]/g.test(this.referral_code)) {
        this.affErr = 0;
        this.affiliateError =
          "Referral Code cannot be Numbers only, must contain at least one character!";
        return;
      }

      let phonePrefix = this.phone.replace(/\D/g, "").substring(0, 3);
      if (!this.allowed_country_codes.includes(phonePrefix)) {
        this.affErr = 0;
        this.affiliateError =
          "Phone number must be in correct international format, and from allowed countries!";
        return;
      }

      const affDetails = {
        fullname: this.fullname,
        occupation: this.occupation,
        location: this.country,
        phone: this.phone,
        referralcode: this.referral_code,
      };

      fetch(process.env.VUE_APP_ROOT_API + `/service/affiliate/registration`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(affDetails),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
            console.log(data)
          if (data.success) {
            this.affiliateSuccess =
              "Success!! Affiliate registered successfully.";
            setTimeout(() => window.location.replace(data.tracker_url), 2500);
          } else {
            this.affErr = 0;
            if(data.message){
                this.affiliateError = data.message;
            }else{
                this.affiliateError = "Error!! Check with customer support."
            }
          }
        });
    },
  },
};
</script>
  
<style scoped>
.error-msg {
  color: #d63301;
  background-color: #ffccba;
  text-align: left;
  padding: 5px;
}
.info {
  color: #00529b;
  background-color: #bde5f8;
  text-align: left;
  padding: 5px;
}
.success {
  color: #4f8a10;
  background-color: #dff2bf;
  text-align: left;
  padding: 5px;
}
.button {
  height: 40px !important;
  border: none !important;
}
button:disabled,
button[disabled] {
  background-color: #cccccc !important;
  color: #666666 !important;
}
</style>